/**
 * Created by Andrey Popov on 5/15/24.
 */

var NoAdsWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        this.product = Product.CreateById("noAds");

        this._super({
            name: "NoAdsWindow",
            title: this.useSlots() ? "NoAdsWindow.title2" : "NoAdsWindow.title",
            content: this.createContent(),
            styles: cleverapps.styles.NoAdsWindow.window,
            notCloseByTouchInShadow: true,
            closeButtonDelay: true,
            footNote: this.createFootnote()
        });

        cleverapps.focusManager.showControlsWhileFocused("MenuBarGoldItem");

        NoAdsWindow.updateNudgeTime();

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.NOADS_PRODUCT.WINDOW_OPEN);
    },

    getPerson: function () {
        return cleverapps.persons.choose("seller", "worker");
    },

    useSlots: function () {
        return cleverapps.config.ui === "wooden";
    },

    createSlotContent: function () {
        var content = new cleverapps.Spine(bundles.noads_window.jsons.noads_gift_json);
        content.setAnimation(0, "animation", true);

        var button = this.createButton();
        var buttonSlot = content.getSlot("slot_button");
        buttonSlot.addChild(button);
        button.setPositionRound({ x: { align: "center" }, y: { align: "center" } });

        var text = Messages.get("NoAdsWindow.note2." + (cleverapps.config.soft ? "hard" : "soft"), {
            amount: this.product.reward.hard
        });
        var note = cleverapps.UI.generateOnlyText(text, cleverapps.styles.FONTS.NOTE_WINDOW_TEXT);
        var noteSlot = content.getSlot("slot_text");
        noteSlot.addChild(note);
        note.fitTo(noteSlot.width, noteSlot.height);
        note.setPositionRound({ x: { align: "center" }, y: { align: "center" } });

        var badge = new cc.Sprite(bundles.noads_window.frames.noads_badge_png);
        var badgeSlot = content.getSlot("slot_badge");
        badgeSlot.addChild(badge);
        badge.setPositionRound({ x: { align: "center" }, y: { align: "center" } });

        var label = cleverapps.UI.generateImageText("+150", cleverapps.styles.FONTS.NOADS_BADGE);
        badge.addChild(label);
        label.setPositionRound(cleverapps.styles.NoAdsWindow.label);
        label.setRotation(30);

        return content;
    },

    createContent: function () {
        if (this.useSlots()) {
            return this.createSlotContent();
        }

        var styles = cleverapps.styles.NoAdsWindow;

        var content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.setContentSize(styles);

        var text = Messages.get("NoAdsWindow.note." + (cleverapps.config.soft ? "hard" : "soft"), {
            amount: this.product.reward.hard
        });
        text = cleverapps.splitHalfByWord(text).join("\n");
        var note = cleverapps.UI.generateOnlyText(text, cleverapps.styles.FONTS.NOTE_WINDOW_TEXT);
        note.fitTo(styles.note.width);
        note.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        content.addChild(note);
        note.setPositionRound(styles.note);

        var giftSpine = new cleverapps.Spine(bundles.noads_window.jsons.noads_gift_json);
        giftSpine.setAnimation(0, "animation", true);
        content.addChild(giftSpine);
        giftSpine.setPositionRound(styles.gift);

        var badge = new TileBadge({
            text: "80%\nOFF",
            rotation: styles.badge.rotation,
            textRotation: styles.badge.textRotation,
            font: cleverapps.styles.FONTS.NOADS_BADGE
        });
        badge.setScale(styles.badge.scale);
        content.addChild(badge);
        badge.setPositionRound(styles.badge);

        var bonusnote = new TextWithIcon("+ $$" + this.product.reward.hard, {
            font: cleverapps.styles.FONTS.NOADS_BONUS_TEXT
        });
        bonusnote.setPositionRound(styles.bonusnote);
        content.addChild(bonusnote);

        var button = this.createButton();
        content.addChild(button);
        button.setPositionRound(styles.button);

        return content;
    },

    createButton: function () {
        var styles = cleverapps.styles.NoAdsWindow.button;

        return new cleverapps.UI.Button({
            text: this.product.getCurrentPrice(),
            width: styles.width,
            height: styles.height,
            onClicked: function () {
                this.product.buy(function (success) {
                    this.close();

                    if (success) {
                        this.product.logBuyItem();
                        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.NOADS_PRODUCT.WINDOW_PURCHASE);
                    }
                }.bind(this));
            }.bind(this)
        });
    },

    createFootnote: function () {
        var styles = cleverapps.styles.NoAdsWindow.footnote;
        var note = new cc.Node();

        var text = cleverapps.UI.generateOnlyText("NoAdsWindow.footnote", cleverapps.styles.FONTS.WHITE_TEXT);
        text.setDimensions(styles.width, 0);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.fitTo(undefined, styles.height);

        note.addChild(text);
        cleverapps.UI.wrap(note);
        note.setAnchorPoint(0.5, 0.5);

        this.underline = cleverapps.UI.drawUnderline(text, styles.underline);
        note.addChild(this.underline);

        cleverapps.UI.onClick(note, this.close.bind(this));
        return note;
    },

    listBundles: function () {
        return ["noads_window"];
    }
});

NoAdsWindow.needToNudge = function () {
    var lastNudgeTime = cleverapps.dataLoader.localStorage.getItem(DataLoader.TYPES.NO_ADS_NUDGE_TIME);

    if (lastNudgeTime && parseInt(lastNudgeTime) + NoAds.NUDGE_TIMEOUT > Date.now()) {
        return false;
    }

    return connector.ads.isInterstitialSupported && cleverapps.noAds.canBuy();
};

NoAdsWindow.updateNudgeTime = function () {
    cleverapps.dataLoader.save(DataLoader.TYPES.NO_ADS_NUDGE_TIME, Date.now());
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    NOTE_WINDOW_TEXT: {
        name: "nostroke",
        size: 46,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    NOADS_BADGE: {
        size: 30,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    NOADS_BONUS_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    }
});

cleverapps.styles.NoAdsWindow = {
    width: 620,
    height: 720,

    note: {
        width: 720,
        x: { align: "center" },
        y: { align: "top", dy: -25 }
    },

    footnote: {
        height: 90,
        underline: {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: 5 },
            height: 3
        }
    },

    badge: {
        scale: 1.1,
        rotation: 0,
        x: { align: "right", dx: 25 },
        y: { align: "center", dy: 90 }
    },

    bonusnote: {
        x: { align: "center" },
        y: { align: "bottom", dy: 115 }
    },

    button: {
        width: 250,
        height: 100,
        x: { align: "center" },
        y: { align: "bottom", dy: 10 }
    },

    gift: {
        x: { align: "center", dx: -20 },
        y: { align: "center", dy: -10 }
    },

    label: {
        x: { align: "center", dx: 10 },
        y: { align: "center", dy: 3 }
    }
};
